import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("Login")
  },
  {
    path: '/admin',
    component: lazyLoad("Admin/Base"),
    children: [
      {
        path: '/',
        redirect: "/admin/rss_feed",
      },
      {
        path: 'rss_feed',
        name: 'AdminRssFeed',
        component: lazyLoad("Admin/RssFeed")
      },
      {
        path: 'sms',
        name: 'AdminSMS',
        component: lazyLoad("Admin/SMS")
      },
      {
        path: 'contests',
        name: 'AdminContests',
        component: lazyLoad("Admin/Contests")
      },
      // ..............................................................................
      {
        path: 'accounting',
        name: 'AdminAccounting',
        component: lazyLoad("Admin/Accounting/Index")
      },
      // ..............................................................................
      {
        path: 'supportCenter',
        name: 'AdminSupportCenter',
        component: lazyLoad("Admin/SupportCenter")
      },
      {
        path: 'visaOptions',
        name: 'AdminVisaOptions',
        component: lazyLoad("Admin/VisaOptions")
      },
      // ..............................................................................
      {
        path: 'newsLink',
        name: 'AdminNewsLink',
        component: lazyLoad("Admin/NewsLink")
      },
      {
        path: 'employer_accounts',
        name: 'AdminEmployerAccounts',
        component: lazyLoad("Admin/EmployerAccounts")
      },
      {
        path: 'employee_accounts',
        name: 'AdminEmployeeAccounts',
        component: lazyLoad("Admin/EmployeeAccounts")
      },
      // ..............................................................................
      {
        path: 'videos',
        name: 'AdminVideos',
        component: lazyLoad("Admin/Videos")
      },
      {
        path: 'videosURL',
        name: 'AdminVideosURL',
        component: lazyLoad("Admin/VideosURL")
      },
      // ..............................................................................
      {
        path: 'stay_guard_messages',
        name: 'AdminStayGuardMessages',
        component: lazyLoad("Admin/StayGuardMessages")
      },
      {
        path: 'stay_guard_logs',
        name: 'AdminStayGuardLogs',
        component: lazyLoad("Admin/StayGuardLogs")
      },
      // ..............................................................................
      {
        path: 'notification_message',
        name: 'AdminNotificationMessage',
        component: lazyLoad("Admin/NotificationMessage")
      },
      {
        path: 'living_expense_calculator',
        name: 'AdminLivingExpenseCalculator',
        component: lazyLoad("Admin/LivingExpenseCalculator")
      },
      {
        path: 'medical_expense_calculator',
        name: 'AdminMedicalExpenseCalculator',
        component: lazyLoad("Admin/MedicalExpenseCalculator")
      },
      {
        path: 'dental_expense_calculator',
        name: 'AdminDentalExpenseCalculator',
        component: lazyLoad("Admin/DentalExpenseCalculator")
      },
      {
        path: 'house_transportation_calculator',
        name: 'AdminHouseTransportationCalculator',
        component: lazyLoad("Admin/HouseTransportationCalculator")
      },
      {
        path: 'massage_spa_treatment_calculator',
        name: 'AdminMassageSpaTreatment',
        component: lazyLoad("Admin/MassageSpaTreatmentCalculator")
      },
      // ..........................................
      {
        path: 'add_education_visa',
        name: 'AdminAddEducationVisa',
        component: lazyLoad("Admin/AddEducationVisa")
      },
      {
        path: 'education_visa_info',
        name: 'AdminEducationVisaInfo',
        component: lazyLoad("Admin/EducationVisaInfo")
      },
      {
        path: 'add_tourism',
        name: 'AdminAddTourism',
        component: lazyLoad("Admin/AddTourism")
      },
      {
        path: 'tourism_info',
        name: 'AdminTourismInfo',
        component: lazyLoad("Admin/TourismInfo")
      },
      {
        path: 'add_legal',
        name: 'AdminAddLegal',
        component: lazyLoad("Admin/AddLegal")
      },
      {
        path: 'legal_info',
        name: 'AdminLegalInfo',
        component: lazyLoad("Admin/LegalInfo")
      },
      // -------------------------------------------------------------
      {
        path: 'create_company',
        name: 'AdminCreateCompany',
        component: lazyLoad("Admin/CreateCompany")
      },
      {
        path: 'create_personal',
        name: 'AdminCreatePersonal',
        component: lazyLoad("Admin/CreatePersonal")
      },
      // -------------------------------------------------------------
      {
        path: 'creation_request',
        name: 'AdminCreationRequest',
        component: lazyLoad("Admin/CreationRequest")
      },
      {
        path: 'company_account',
        name: 'AdminCompanyAccount',
        component: lazyLoad("Admin/CompanyAccount")
      },
      {
        path: 'approval_request',
        name: 'AdminApprovalRequest',
        component: lazyLoad("Admin/ApprovalRequest")
      },
      {
        path: 'verify_img_company',
        name: 'AdminVerifyImgCompany',
        component: lazyLoad("Admin/VerifyImgCompany")
      },
      {
        path: 'verify_document_company',
        name: 'AdminVerifyDocumentCompany',
        component: lazyLoad("Admin/VerifyDocumentCompany")
      },
      // -------------------------------------------------------------
      {
        path: 'employee_account',
        name: 'AdminEmployeeAccount',
        component: lazyLoad("Admin/EmployeeAccount")
      },
      // -------------------------------------------------------------
      {
        path: 'personal_account',
        name: 'AdminPersonalAccount',
        component: lazyLoad("Admin/PersonalAccount")
      },
      // -------------------------------------------------------------
      {
        path: 'user_account',
        name: 'AdminUserAccount',
        component: lazyLoad("Admin/UserAccount")
      },
      // -------------------------------------------------------------
      {
        path: 'payment_list',
        name: 'AdminPaymentList',
        component: lazyLoad("Admin/PaymentList")
      },
    ]
  },
  // {
  //   path: '/admin/articles',
  //   component: lazyLoad("Admin/Articles/Base"),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'List',
  //       component: lazyLoad("Admin/Articles/List")
  //     },
  //     {
  //       path: 'create',
  //       name: 'Create',
  //       component: lazyLoad("Admin/Articles/Create")
  //     },
  //     {
  //       path: 'update/:id',
  //       name: 'Update',
  //       component: lazyLoad("Admin/Articles/Update")
  //     },
  //     {
  //       path: 'update',
  //       redirect: "/admin/articles"
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
